<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
             <filter-regional v-model:value="regionals" />
          </div>
          <div class="card-body ml-2 mt-2">
              <filter-area v-model:value="areas" v-model:province="province" />
          </div>
          <div class="card-body mt-2 ml-2">
              <filter-brands v-model:value="brands" v-model:brand="brands" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder">
          <div class="card-body ml-2 mt-2">
              <filter-province v-model:value="province" v-model:regional="regionals" />
          </div>
          <div class="card-body mt-2 ml-2">
              <filter-kabupaten-kota v-model:value="kabupaten" v-model:area="areas" />
          </div>
          <div class="card-body ml-2 mt-2">
                <a-date-picker class="mr-2" v-model:value="value2" :format="dateFormatList" placeholder=" Tanggal" />
                <a-date-picker v-model:value="value2" :format="dateFormatList" placeholder=" Tanggal" />
          </div>
        </div>
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
            <a-button class="m-2" type="primary" title="cari" @click="search">
          <i class="fa fa-search" aria-hidden="true"></i>
        </a-button>
        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
// import MModal from './modal'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterBrands from '@/components/filter/FilterBrand'

export default {
  name: 'VbDashboard',
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    // FilterDistributor,
    FilterBrands,
  },
}
</script>
